/* Color palette */

$black: #000000;
$sky-blue: #6c9aff;
$clear-blue: #2699fb;
$dodger-blue: #4c84ff;
$okto-blue: #0069b3;
$okto-blue-hover: #0076CA;
$cornflower-blue: #3f6dd1;
$bluey-grey-8: rgba(176,183,199,0.08);
$bluey-grey-58: rgba(176, 183, 199, 0.58);
$pale-grey: #f7f8f9;
$pale-grey-two: #e9ebef;
$cloudy-blue: #bec4d1;
$bluey-grey: #b0b7c7;
$cool-grey: #9196a3;
$coral: #ff433d;
$white: #ffffff;
$tomato: #e83d38;
$greenish-teal: #2eca86;
$shamrock-green: #00bf6c;
$emerald: #009d59;
$light-gold: #ffd256;
$sun-yellow: #ffc931;
$macaroni-and-cheese: #e8b72d;
$greedy-orange: #f68b24;
$brownish-grey: #707070;
$pale-grey-three: #edeff6;
$gunmetal: #4d4f5c;
$light-periwinkle: #dbdee5;
$excel-green: #1d6f41;

$primary: orange;
$secondary: white;
$success: green;
$warning: orange;
$disabled: lightGrey;
$info: gray;
$secondary: $cool-grey;
$border: lightGrey;
$borderRadius: 25px;

$brand-color: $okto-blue;
$brand-color-hover: $okto-blue-hover; 
$brand-color-light: $okto-blue-hover;
$body-bg: $pale-grey;

$skroutz: $greedy-orange;

$table-border: $pale-grey-three;
$table-header-bg-admin: $bluey-grey-8;
$table-header-border-admin: $cloudy-blue;

$card-title: $gunmetal;

$quickFolderBG: $sun-yellow;

$danger: $coral;
$danger-hover: $tomato;
$success: $shamrock-green;
$success-hover: $emerald;
$warning: $sun-yellow;
$warning-hover: $macaroni-and-cheese;

$text-primary: $black;

/* Text styles */

@mixin Header1 {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    line-height: 1.21;
    text-align: left;
    color: $black;
}

@mixin Header2 {
    font-family: Roboto, sans-serif;
    font-size: 22px;
    line-height: 1.23;
    text-align: left;
    color: $black;
}

@mixin Header3 {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.22;
    text-align: left;
    color: $black;
}

@mixin Text {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.19;
    text-align: left;
    color: $black;
}

/* Breakpoints */
$sm: 540px;
$md: 720px;
$lg: 1200px;
$xl: 1336px
